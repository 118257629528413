var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isScroll ? 'roll_body' : 'body'},[_c('div',{staticClass:"w1320 flex-row flex-between flex-align box"},[_c('div',{staticClass:"flex-row flex-align"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logoUrl}}),_c('img',{staticClass:"bar-title",attrs:{"src":require("@/assets/nav-bar-title.png"),"alt":""}})]),_c('div',{class:_vm.isScroll
          ? 'flex-row flex-align custom-nav-bar-normal h100'
          : 'flex-row flex-align custom-nav-bar h100'},_vm._l((_vm.navBars),function(item,index){return _c('div',{key:index,staticClass:"div-transition h100 flex-column flex-center border-right-box",on:{"click":function($event){return _vm.handleNavBar(item, '')}}},[_c('span',{class:_vm.current == index
              ? _vm.isScroll
                ? 'item-list-normal'
                : 'item-list'
              : ''},[_vm._v(_vm._s(item.label))])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }