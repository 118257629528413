<!--
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 案例：导航菜单
 * @FilePath: \tp-vue2\src\page\index\nav-bar.vue
-->

<template>
  <div :class="isScroll ? 'roll_body' : 'body'">
    <div class="w1320 flex-row flex-between flex-align box">
      <div class="flex-row flex-align">
        <img class="logo" :src="logoUrl"/>
        <img class="bar-title" src="~@/assets/nav-bar-title.png" alt=""/>
      </div>

      <div
          :class="
          isScroll
            ? 'flex-row flex-align custom-nav-bar-normal h100'
            : 'flex-row flex-align custom-nav-bar h100'
        "
      >
        <div
            class="div-transition h100 flex-column flex-center border-right-box"
            v-for="(item, index) in navBars"
            :key="index"
            @click="handleNavBar(item, '')"
        >
          <span
              :class="
              current == index
                ? isScroll
                  ? 'item-list-normal'
                  : 'item-list'
                : ''
            "
          >{{ item.label }}</span
          >
        </div>
        <!-- <el-popover
          placement="bottom"
          trigger="hover"
          popper-class="tag-popover"
          width="140"
        >
          <div class="xiaoyuantong-div">
            <div>
              <a href="http://sjzbooking.chinau8.cn/" target="_blank">
                学校端</a
              >
            </div>
            <div class="line"></div>
            <div>
              <a href="http://sjzjg.chinau8.cn/" target="_blank"> 交管端</a>
            </div>
            <div class="line"></div>
            <div>
              <a href="http://education.chinau8.cn/" target="_blank"> 教育端</a>
            </div>
          </div>
          <div class="xiaoyuantong" slot="reference">
            <span :class="isScroll ? 'xiaoyuantong-normal' : ''">校园通</span>
          </div>
        </el-popover> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      isMenuShow: false,
      current: 0,
      navBars: [
        {
          label: "首页",
          value: "/",
          index: 0,
        },
        {
          label: "系统功能",
          value: "/product",
          index: 1,
        },
        {
          label: "城市合伙人",
          value: "/partner",
          index: 2,
        },
        {
          label: "关于我们",
          value: "/about/aboutus",
          index: 3,
        },
      ],
      currentAddress: "",
      logoUrl: require("@/assets/logo_normal.png"),
      childList: [
        {
          label: "案例详情",
          value: "/casus/detail",
          index: 1,
        },
        {
          label: "案例列表",
          value: "/casus",
          index: 1,
        },
      ],
      scroll: "",
      isScroll: false,
    };
  },
  watch: {
    $route: "newAddress",
  },
  created() {
    this.newAddress();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * @description: 路由跳转
     * @param {*} path 路径
     * @return {*}
     */
    handleNavBar(item) {
      this.current = item.index;
      this.$router.push({path: item.value});
    },
    newAddress() {
      let path = this.$route.path;
      //是不是子页面
      let isChild = true;
      this.navBars.forEach((res) => {
        if (path == res.value) {
          this.current = res.index;
          isChild = false;
          if (res.index == 0) {
            this.logoUrl = require("@/assets/u8/tianhua_logo.png");
          } else {
            this.logoUrl = require("@/assets/u8/tianhua_logo.png");
          }
        }
      });
      if (isChild) {
        this.childList.forEach((res) => {
          if (path.includes(res.value)) {
            this.current = res.index;
            this.logoUrl = require("@/assets/u8/tianhua_logo.png");
          }
        });
      }
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
      //导航栏高度70px 超过70显示白底
      if (this.scroll > 110) {
        this.isScroll = true;
        this.logoUrl = require("@/assets/u8/tianhua_logo.png");
      } else {
        this.logoUrl = require("@/assets/u8/tianhua_logo.png");
        this.isScroll = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  // background-color: #0d1c2e;
  backdrop-filter: blur(20px);
  color: #fff;

  .head-title {
    font-size: 32px;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
  }

  .bar-title {
    width: 222px;
    height: 32px;
  }
}

.roll_body {
  background-color: #fff;
  backdrop-filter: blur(20px);
  color: #fff;

  .head-title {
    font-size: 32px;
    font-weight: 400;
    color: #000;
    line-height: 38px;
  }
}

.box {
  height: 80px;

  .logo {
    width: 214px;
    height: 70px;
    margin-right: 35px;
  }
}

.nav-main {
  font-size: 24px;
  background-color: #0d1c2e;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.nav-nums {
  width: 130px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.item-list {
  color: #fff !important;
  font-weight: bold;
}

.item-list-normal {
  color: #066bf1 !important;
  font-weight: bold;
}

.nav-nums li {
  display: none;
  list-style: none;
  height: 40px;
  line-height: 40px;
}

.nav-nums:hover li {
  display: block;
}

.custom-nav-bar > div {
  padding: 0 33px;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  position: relative;
  height: 36px;
  border-right: 2px solid rgba(217, 217, 217, 0.6);
}

.custom-nav-bar > div:last-child {
  padding-right: 0;
  border-right: none;
}

.custom-nav-bar > div:hover {
  color: rgba(255, 255, 255, 1);
}

.custom-nav-bar-normal > div {
  padding: 0 33px;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  position: relative;
  height: 36px;
  border-right: 2px solid rgba(217, 217, 217, 0.6);
}

.custom-nav-bar-normal > div:last-child {
  padding-right: 0;
  border-right: none;
}

.custom-nav-bar-normal > div:hover {
  color: #066bf1;
}

// .custom-nav-bar > div:nth-of-type(3):hover {
//   .navbar-list {
//     display: flex;
//   }
// }

.navbar-list {
  width: 232px;
  background: #fff;
  border-radius: 6px;
  //margin-top: 300px;
  position: absolute;
  top: 130px;
  left: 50%;
  //transform: translateX(-50%);
  margin-left: -116px;
  z-index: 20;
  display: none;
}

// .navbar-list > span {
//   padding: 16px 0;
//   color: #333333;
//   border-bottom: 1px solid #e5e5e5;
//   width: 100%;
// }

// .navbar-list > span:hover {
//   color: rgba(255, 255, 255, 1);
// }

// .navbar-list > span:last-child {
//   border-bottom: none;
// }
::v-deep .xiaoyuantong {
  transition: all 0.3s ease-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 33px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;

  .xiaoyuantong-normal {
    color: #333333;
  }
}

.tag-popover {
  .xiaoyuantong-div {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    letter-spacing: 1px;
    text-align: center;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    opacity: 1;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.tag-popover[x-placement^="bottom"] {
  min-width: 120px;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
<style lang="less">
.el-popover {
  box-sizing: border-box;
}
</style>
