<!--
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 案例：首页（入口页面）
 * @FilePath: \tp-vue2\src\page\index\index.vue
-->

<template>
  <div class="">
    <!-- 这里可以放导航菜单，所有页面通用，案例如下，根据自己需求调整 -->
    <div class="nav-bar-comm-box">
      <nav-bar></nav-bar>
    </div>
    <!-- 这里放一个嵌套路由，用来导航菜单对应的子页面，案例如下，根据自己需求调整 -->
    <div class="router-view-index-box">
      <router-view />
    </div>
    <div>
      <FootBar></FootBar>
    </div>
  </div>
</template>

<script>
import NavBar from "./nav-bar";
import FootBar from "./foot-bar";
import website from "@/config/website";

export default {
  name: "Index",
  components: {
    NavBar,
    FootBar,
  },
  data() {
    return {};
  },
  created() {},
  destroyed() {},
  mounted() {
    // 进入第一页
    const path = website.firstPageRouterPath;
    this.$router.push({ path });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.index__box {
}

.nav-bar-comm-box {
  //z-index: 20;
  position: fixed;
  z-index: 1000;
  top: 0px;
  width: 100%;
}
</style>
