<template>
  <div class="foot">
    <div class="foot_link flex flex-between">
      <div class="left">
        <div><span>联系电话</span>：400-780-0159(服务热线)</div>
        <div><span>邮箱账号</span>：tianhua@skygovern.com</div>
        <div>
          <span>地址</span>：金华市金东区多湖街道光南路广润翰城北门4幢120室
        </div>
      </div>
      <div class="right">
        <div class="right_box">
          <div class="right_title">服务体系</div>
          <div class="right_desc">软件研发</div>
          <div class="right_desc">技术咨询</div>
          <div class="right_desc">平台运营</div>
          <div class="right_desc">管理咨询</div>
          <div class="right_desc">培训及认证服务</div>
        </div>
        <div class="right_box">
          <div class="right_title">多重保障</div>
          <div class="right_desc">安全保障</div>
          <div class="right_desc">服务保障</div>
          <div class="right_desc">技术保障</div>
          <div class="right_desc">时效保障</div>
        </div>
        <div class="right_box">
          <div class="right_title">快速链接</div>
          <div class="right_desc" @click="jumpLink" style="cursor: pointer">公司官网</div>
        </div>
      </div>
    </div>
    <div class="ICP">
      <div>www.mjpjpt.com</div>
      <img src="../../assets/homepage/insignia.png"/>
      <div>
        <span
        ><a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33070302100119"
            target="_blank"
        >浙公网安备 33070302100222号</a
        ></span
        >
        <span
        ><a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
        >备案许可证号：浙ICP备10213264号-6
          </a>
        </span>
        <span
        ><a href="https://www.miit.gov.cn" target="_blank">
            中华人民共和国工业和信息化部</a
        ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot-bar",
  components: {},
  data() {
    return {};
  },

  mounted() {
  },
  methods: {
    goNews(category) {
      this.$router.push({
        name: "news",
        query: {
          categoryId: category,
        },
      });
    },
    goAbout(category) {
      this.$router.push({
        name: "aboutUs",
        query: {
          activeTab: category,
        },
      });
    },
    jumpLink() {
      window.open('http://www.skygovern.com/')
    }
  },
};
</script>

<style lang="scss" scoped>
.foot {
  width: 1920px;
  // margin-top: 20px;
  position: relative;
  box-sizing: border-box;

  .foot_link {
    width: 100%;
    box-sizing: border-box;
    height: 270px;
    background: url("https://imagepub.chinau8.cn/footer-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 40px 210px;

    .left {
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #fcfcfc;
      line-height: 21px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: left;

      span {
        width: 80px;
        display: inline-block;
        text-align-last: justify;
      }
    }

    .right {
      display: flex;

      .right_box {
        margin-right: 40px;
        height: 100%;
      }

      .right_title {
        font-size: 18px;
        font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
        font-weight: 800;
        color: #fcfcfc;
        line-height: 21px;
      }

      .right_desc {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        line-height: 16px;
        margin-top: 15px;
      }
    }
  }

  .ICP {
    background-color: rgba(3, 5, 7, 1);
    width: 100%;
    height: 60px;
    font-size: 14px;
    color: rgba(187, 187, 187, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: -1px;

    img {
      width: 20px;
      height: 20px;
      margin: 0px 6px 0px 20px;
    }

    span {
      margin-right: 20px;
    }

    a {
      font-size: 14px;
      color: rgba(187, 187, 187, 1);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
